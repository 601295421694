





















import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';

@Component({
  components: {
    LoadingIndicator,
  },
})
export default class UpdatingCampaignDetailsModal extends Vue {
  @Prop({ default: 'Updating your campaign\'s details' }) message: string;

  showUpdating: boolean = true;
}
