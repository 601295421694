


































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { estimateImpressions, maximumBudgetCents, minimumBudgetCents } from '@/shared/lib/budgetOptions';

@Component({
})
export default class BudgetSlider extends Vue {
  $refs!: {
    budgetSlider: any,
  };

  @Prop()
  value: number;

  @Prop({ default: minimumBudgetCents / 100 })
  minBudget: number;

  @Prop({ default: maximumBudgetCents / 100 })
  maxBudget: number;

  @Prop({ default: 7 })
  duration: number;

  @Getter('layout/isMobile') isMobile: boolean;

  budgetStep: number = 5;

  get estimatedReach() {
    return estimateImpressions(this.budget);
  }

  get sliderWidth() {
    let clientWidth = this.$refs.budgetSlider ? this.$refs.budgetSlider.clientWidth : 400;

    if (this.isMobile) {
      clientWidth = this.$refs.budgetSlider ? this.$refs.budgetSlider.clientWidth : 334;
    }
    return (clientWidth * this.truePct) / 100;
  }

  get truePct() {
    return ((this.budget - this.minBudget) / (this.maxBudget - this.minBudget)) * 100;
  }

  get budgetLeftPosition() {
    const percent = this.truePct;
    let maxRight = 85;
    let minLeft = 0;
    let fullValue = 79;
    if (this.isMobile) {
      maxRight = 80;
      minLeft = 0;
      fullValue = 70;
    }

    let retval = (percent * maxRight) / 100;
    retval = Math.max(retval, minLeft);
    retval = Math.min(retval, maxRight);
    if (percent >= 100) {
      retval = Math.min(retval, fullValue);
    }
    return `${retval}%`;
  }

  get budget(): number {
    return this.value;
  }

  set budget(value) {
    let newValue = value;
    if (newValue < this.minBudget) {
      newValue = this.minBudget;
    }
    this.$emit('input', newValue);
  }

  get durationValue(): string {
    return this.duration.toString();
  }

  set durationValue(value) {
    this.$emit('changeDuration', parseInt(value, 10));
  }
}
