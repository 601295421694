













import { Component, Prop, Vue } from 'vue-property-decorator';
import PromoPageModal from '@/shared/components/common/PromoPageModal.vue';
import PromoPagePreviewModal from '@/shared/components/common/PromoPagePreviewModal.vue';

@Component({
  components: {
    PromoPageModal,
    PromoPagePreviewModal,
  },
})
export default class PromoPageModals extends Vue {
  $refs!: {
    promoPageModal: PromoPageModal,
    promoPagePreviewModal: PromoPagePreviewModal,
  };

  @Prop({ required: true })
  campaignId: string;

  show() {
    this.$refs.promoPageModal.show();
  }

  hide() {
    this.$refs.promoPageModal.hide();
    this.$refs.promoPagePreviewModal.hide();
  }

  handleViewPromoPageClick() {
    this.$refs.promoPageModal.hide();
    this.$refs.promoPagePreviewModal.show();
  }
}
