

















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import StrategyGroup from '@/shared/components/builder/StrategyGroup.vue';
import StrategyTemplate from '@/shared/components/builder/StrategyTemplate.vue';
import FacebookTemplate from '@/shared/components/common/FacebookTemplate.vue';
import InstagramTemplate from '@/shared/components/common/InstagramTemplate.vue';
import MobilePager from '@/shared/components/common/MobilePager.vue';
import { FacebookPage } from '@/shared/gen/messages.pisa';
import DesktopPager from '@/shared/components/common/DesktopPager.vue';
import * as PreviewFunctions from '@/shared/lib/previewFunctions';
import EditAdButton from '@/shared/components/builder/EditAdButton.vue';

@Component({
  mixins: [],
  components: {
    EditAdButton,
    DesktopPager,
    MobilePager,
    StrategyGroup,
    StrategyTemplate,
    FacebookTemplate,
    InstagramTemplate,
  },
  props: {},
  validations: {},
})
export default class GridStrategy extends Vue {
  @Prop({ default: 'StrategyGroup' })
  strategyType: string;

  @Prop({ default: 'Local Love' })
  strategyTitle: string;

  @Prop({ default: 'Get Exposure with ads show to cities that love your style of music.' })
  strategyDescription: string;

  @Prop({ default: '' })
  adType: string;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: () => [] })
  strategyData: object[];

  @Prop({ default: () => {} })
  syncedPage: FacebookPage;

  @Prop({ default: -1 })
  numStrategiesToDisplay: number;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  strategyIndex: number = 0;

  pages: any[] = [];

  get hasPromoPage(): boolean {
    return this.strategyData.some((i: any) => i.properties.retailers.indexOf('zire') !== -1);
  }

  get adsData() {
    if (this.strategyData) {
      if (this.strategyIndex % this.numStrategiesToDisplay !== 0) {
        // handle the case where mobile users have started browsing through the samples and changed orientation -
        // ending up on what would be a half page, which would error out if they went backwards, and display
        // something weird like 2.5/5
        this.strategyIndex = this.strategyIndex - 1 > 0 ? this.strategyIndex - 1 : 0;
      }
      if (this.strategyIndex + this.numStrategiesToDisplay > this.strategyData.length) {
        return this.strategyData.slice(Math.max(this.strategyData.length - this.numStrategiesToDisplay, 0));
      }
      return this.strategyData.slice(this.strategyIndex, this.strategyIndex + this.numStrategiesToDisplay);
    }
    return [];
  }

  editAd(creativeId: string) {
    this.$emit('edit', creativeId);
  }

  navigate(isNext: boolean = false) {
    if (isNext) {
      if (this.strategyIndex >= this.strategyData.length - this.numStrategiesToDisplay) {
        this.strategyIndex = 0;
      } else {
        this.strategyIndex += this.numStrategiesToDisplay;
      }
    } else if (this.strategyIndex === 0) {
      this.strategyIndex = this.strategyData.length - this.numStrategiesToDisplay;
    } else {
      this.strategyIndex -= this.numStrategiesToDisplay;
    }
  }

  socialAdClicked() {
    PreviewFunctions.socialAdClicked();
  }

  interactiveAdClicked(data: any) {
    PreviewFunctions.interactiveAdClicked(data);
  }

  showPromoPageModal() {
    this.$emit('show-promo-page-modal', this.adType);
  }
}
