










import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})

export default class StrategyGroup extends Vue {
  $refs!: {
    [key: string]: any;
  };

  @Prop({ default: 'strategyGroup' })
  strategyType: string;

  @Prop({ default: [] })
  strategies: Array<object>;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  get isProcessing() {
    return this.$store.getters['recommendedAds/isProcessing'];
  }
}
