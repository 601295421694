


























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PromoPageUpsellBanner extends Vue {
  handleClick(e: Event) {
    e.preventDefault();
    this.$emit('click');
  }

  handleMobileClick(e: Event) {
    if (!e.defaultPrevented && (this.$mq === 'xs' || this.$mq === 'sm')) {
      this.handleClick(e);
    }
  }

  handleDismiss(e: Event) {
    e.preventDefault();
    this.$emit('dismiss');
  }
}
