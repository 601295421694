import { addMonths } from 'date-fns';
import docCookies from '@/shared/lib/docCookies';

export const enum UpsellBanner {
  PromoPage = 1,
}

const expirationMap = {
  [UpsellBanner.PromoPage]: new Date(2021, 4, 7), // May 7, 2021
};

const cookieName = 'zub';
const separator = '~';

function isExpired(upsellBanner: UpsellBanner): boolean {
  return expirationMap[upsellBanner] && expirationMap[upsellBanner] < new Date();
}

function getDismissedUpsellBannerIDs() {
  const cookieValue = (docCookies.getItem(cookieName) || '');
  if (cookieValue === '') {
    return [];
  }
  return cookieValue.split(separator);
}

export function dismissUpsellBanner(upsellBanner: UpsellBanner, expiryMonths: number = 60) {
  const bannerIDs = getDismissedUpsellBannerIDs();
  if (bannerIDs.indexOf(`${upsellBanner}`) === -1) {
    bannerIDs.push(`${upsellBanner}`);
    docCookies.setItem(cookieName, bannerIDs.join(separator), addMonths(new Date(), expiryMonths));
  }
}

export function showUpsellBanner(upsellBanner: UpsellBanner): boolean {
  if (isExpired(upsellBanner)) {
    return false;
  }
  return getDismissedUpsellBannerIDs().indexOf(`${upsellBanner}`) === -1;
}
