














































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IApplyCouponResponse } from '@/shared/gen/messages.pisa';

@Component({})
export default class CouponForm extends Vue {
  $refs!: {
    couponInput: HTMLInputElement,
  };

    @Prop({ default: false }) applying: boolean;

    @Prop({ default: '' }) error: string;

    @Prop({ default: '' }) coupon: string;

    @Getter('payment/appliedCoupon') appliedCoupon: IApplyCouponResponse;

    code: string = '';

    created() {
      this.code = this.coupon;
    }

    clear() {
      this.code = '';
    }

    @Getter('layout/isMobile') isMobile: boolean;

    focus() {
      this.$refs.couponInput.focus();
      if (this.isMobile) {
        this.$refs.couponInput.scrollIntoView();
      }
    }

    applyCoupon() {
      if (this.code.length > 0) {
        this.$emit('apply', this.code);
      }
    }

    handleClear() {
      this.clear();
      this.$emit('input', this.code);
      this.focus();
    }

    handleInput(evt: any) {
      this.code = evt.target.value;
      this.$emit('input', this.code);
    }
}
