var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleModal',{ref:"modal",attrs:{"modal-id":"song-upload-modal","size":"lg","white":""},on:{"shown":_vm.handleModalShown,"hide":_vm.handleModalHidden},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"song-library-modal__header"},[_c('span',{staticClass:"copy"},[_vm._v(" Choose a song ")]),(!_vm.isLoggedInWithEmail && !_vm.isMobile)?_c('span',{staticClass:"login-prompt"},[_c('a',{attrs:{"href":"javascript:;"},domProps:{"textContent":_vm._s('Login')},on:{"click":_vm.login}}),_vm._v(" to access your library ")]):_vm._e()])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"song-library-modal__footer copy"},[_c('ModalButton',{staticClass:"song-library-modal__footer__button",attrs:{"variant":"none","disabled":_vm.saveDisabled,"text":'Save'},on:{"click":_vm.commitSelectedAudio}})],1)]},proxy:true}])},[_c('div',{staticClass:"song-library-modal__body",class:{
      'song-library-modal__body--login': !_vm.isLoggedInWithEmail,
      'song-library-modal__body--not-bottom': _vm.blurBottom
    }},[_c('UploaderAudio',{ref:"uploader",staticClass:"song-library-modal__body__upload",class:{
        'song-library-modal__body__upload--hidden': !_vm.showUpload,
        'song-library-modal__body__upload--not-top': _vm.blurTop
      },attrs:{"button-class":'song-library-modal__body__upload__button',"button-icon":['fas', 'plus'],"button-text":'Upload'},on:{"upload-audio":_vm.uploadAudio}}),_c('div',{ref:"songList",staticClass:"song-library-modal__body__song-list",class:{
        'song-library-modal__body__song-list--expanded': !_vm.showUpload,
        'song-library-modal__body__song-list--login': !_vm.isLoggedInWithEmail
      },on:{"&scroll":function($event){return _vm.handleSongsScroll($event)}}},[_c('div',{staticClass:"spacer"}),_vm._l((_vm.audioList),function(song){return _c('a',{key:("song_" + (song.id)),staticClass:"song-library-modal__body__song-list__song copy",class:{ 'selected': _vm.isSongSelected(song)},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.handleAudioSelect(song)}}},[_c('div',{staticClass:"song-library-modal__body__song-list__song__playpause",class:{ 'playing': _vm.playingAudioID === song.id },on:{"click":function($event){$event.stopPropagation();return _vm.playPauseAudio(song)}}},[_c('play-pause-button',{attrs:{"audio-id":song.id,"playing-id":_vm.playingAudioID},on:{"click":function($event){return _vm.playPauseAudio(song)}}})],1),_c('div',{staticClass:"song-library-modal__body__song-list__song__text copy"},[_vm._v(" "+_vm._s(_vm.songName(song.source.name))+" ")]),(_vm.isSongSelected(song))?_c('fa-icon',{staticClass:"song-library-modal__body__song-list__song__selected fa-lg",attrs:{"icon":['fas', 'check']}}):_vm._e()],1)})],2),(!_vm.isLoggedInWithEmail && _vm.isMobile)?_c('div',{staticClass:"song-library-modal__body__login-prompt copy"},[_c('a',{staticClass:"link",attrs:{"href":"javascript:;"},domProps:{"textContent":_vm._s('Login')},on:{"click":_vm.login}}),_vm._v(" to access your library ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }