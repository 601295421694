




























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { email, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { Getter } from 'vuex-class';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';
import Env from '@/shared/lib/env';
import { passwordRule } from '@/shared/lib/validationRules';
import { BL_LOGIN } from '@/shared/lib/api';

@Component({
  mixins: [validationMixin],
  components: {
    MaterialInputField,
  },
  validations: {
    newEmailAddr: { email, required: requiredIf('submitted') },
    pwd: { passwordRule, required: requiredIf('submitted') },
  },
})
export default class Signup extends Vue {
  $refs!: {
    password: any,
  };

  @Getter('profile/userEmail') userEmail: string;

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  get disabled() {
    return this.isLoggedInWithEmail;
  }

  bandlabOnly: boolean = false;

  newEmail: string = '';

  pwd: string = '';

  emailInUse: boolean = false;

  submitted: boolean = false;

  pwdVisible: boolean = false;

  consents = this.$store.getters['profile/consentMap'];

  requiresGdprConsent: boolean = Env.country.gdpr;

  touchMap = new WeakMap();

  get termsConsent() {
    return this.consents.terms;
  }

  set termsConsent(consent) {
    this.consents.terms = consent;
    if (!this.requiresGdprConsent) {
      this.consents.privacy = consent;
    }
  }

  get emailInvalid() {
    return this.$v.newEmailAddr!.$dirty && !this.disabled && this.$v.newEmailAddr!.$invalid;
  }

  get passwordInvalid() {
    return this.$v.pwd!.$dirty && !this.disabled && this.$v.pwd!.$invalid;
  }

  get hasError() {
    return this.emailInvalid || this.passwordInvalid || !this.consents.terms || !this.consents.privacy;
  }

  handleLoginBL() {
    if (BL_LOGIN) window.location.href = `${BL_LOGIN}?redirect=${window.location.pathname}`;
  }

  delayTouch($v: any) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 1000));
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  resetElement($v: any) {
    $v.$reset();
  }

  get newEmailAddr() {
    return this.newEmail;
  }

  set newEmailAddr(value: string) {
    this.newEmail = value;
  }

  mounted() {
    if (this.disabled) {
      this.newEmail = this.userEmail;
      this.pwd = '******';
    } else {
      this.newEmail = '';
      this.pwd = '';
    }
  }

  togglePwdVisibility() {
    this.pwdVisible = !this.pwdVisible;
    return false;
  }

  login() {
    this.$root.$emit('bv::show::modal', 'login-modal');
  }
}
