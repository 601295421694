import { render, staticRenderFns } from "./RecommendedAds.vue?vue&type=template&id=0b954ef5&scoped=true&"
import script from "./RecommendedAds.vue?vue&type=script&lang=ts&"
export * from "./RecommendedAds.vue?vue&type=script&lang=ts&"
import style0 from "./RecommendedAds.vue?vue&type=style&index=0&id=0b954ef5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b954ef5",
  null
  
)

export default component.exports