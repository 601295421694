import Honeybadger from 'honeybadger-js';

function retailerMapping(retailer: string, creativeId: string): string {
  switch (retailer) {
    case 'apple':
      return 'Apple Music';
    case 'google':
      return 'Google Play';
    case 'spotify':
      return 'Spotify';
    case 'deezer':
      return 'Deezer';
    case 'bandlab':
      return 'BandLab';
    default:
      Honeybadger.notify('Unknown retailer', 'RetailerMappingError', {
        context: {
          creativeId,
          retailer,
        },
      });
      return '';
  }
}

export function interactiveAdClicked(data: any) {
  let retailerName = '';
  if (data.retailer && data.retailer !== 'other') {
    retailerName = retailerMapping(data.retailer, data.id);
  }
  if (retailerName) {
    // eslint-disable-next-line no-alert
    window.alert(`Once your campaign is live, clicking here will take fans to your release on ${retailerName}.`);
  } else {
    // eslint-disable-next-line no-alert
    window.alert('Once your campaign is live, clicking here will take fans to your release.');
  }
}

export function socialAdClicked() {
  // eslint-disable-next-line no-alert
  window.alert('Once your campaign is live, fans can engage with your ads to discover your release and social media pages.');
}
