
































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';

@Component({
  components: {
    SimpleModal,
  },
})
export default class PromoPageModal extends Vue {
  $refs!: {
    modal: SimpleModal,
  };

  @Getter('onboarding/draftHasAudio') hasAudio: boolean;

  show() {
    this.$refs.modal.show();
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }
}
