





















































































































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { BvModalEvent } from 'bootstrap-vue';
import { Getter } from 'vuex-class';
import { UserAudio } from '@/shared/gen/messages.pisa';
import ModalButton from '@/shared/components/modals/ModalButton.vue';
import PlayPauseButton from '@/shared/components/common/PlayPauseButton.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import UploaderAudio from '@/shared/components/form/UploaderAudio.vue';

interface AudioPlayer {
  id: string,
  player: HTMLAudioElement,
}

/*
Magic #.
Must be larger than border of the upload button so the border disappears on mobile scroll.
Cannot be much larger than the border or the fade effect goes away
*/
const borderBuffer = 5;

const rowHeight = 48; // Same as css variable row-height below

const scrollBuffer = 48; // Same as css variable row-height below

const truncateLength = 25;

@Component({
  components: {
    ModalButton,
    PlayPauseButton,
    SimpleModal,
    UploaderAudio,
  },
})
export default class SongLibraryModal extends Vue {
  $refs!: {
    modal: SimpleModal,
    songList: HTMLDivElement,
    uploader: UploaderAudio,
  };

  @Prop({ required: true }) audioList: UserAudio[];

  @Prop({ required: true }) selectedAudio: UserAudio[];

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  audioPlayer?: AudioPlayer;

  blurBottom: boolean = false;

  blurTop: boolean = false;

  lastButtonPosition: number = 0;

  lastScrollPosition: number = 0;

  newSelectedAudio: UserAudio[] = [];

  playingAudioID: string = '';

  showUpload: boolean = true;

  handleModalShown() {
    this.newSelectedAudio = this.selectedAudio;
    this.lastScrollPosition = 0;
    this.blurBottom = this.$refs.songList.scrollTop < this.$refs.songList.scrollHeight - this.$refs.songList.offsetHeight;
  }

  handleModalHidden(e: BvModalEvent) {
    if (this.audioPlayer) {
      if (!this.audioPlayer.player.paused) {
        this.audioPlayer.player.pause();
      }
      delete this.audioPlayer;
      this.playingAudioID = '';
    }
    if (e.trigger === 'login') {
      this.$root.$once('bv::modal::shown', (evt: BvModalEvent) => {
        if (evt.componentId === 'login-modal') {
          this.$root.$once('bv::modal::hide', (event: BvModalEvent) => {
            if (event.trigger === 'login') {
              this.$nextTick(() => {
                this.$refs.modal.show();
              });
            }
          });
        }
      });
    }
  }

  get saveDisabled() {
    return this.audioList.length === 0;
  }

  commitSelectedAudio() {
    const nothingChanged = (this.selectedAudio.length === 0 && this.newSelectedAudio.length === 0)
      || (this.selectedAudio.length > 0 && this.newSelectedAudio.length > 0 && this.selectedAudio[0].id === this.newSelectedAudio[0].id);
    if (!nothingChanged) {
      this.$emit('chosen', this.newSelectedAudio);
    }
    this.$refs.modal.hide();
  }

  handleAudioSelect(audio: UserAudio) {
    if (this.newSelectedAudio.length > 0 && audio.id === this.newSelectedAudio[0].id) {
      this.newSelectedAudio = [];
    } else {
      this.newSelectedAudio = [audio];
    }
  }

  handleSongsScroll(e: Event) {
    const target = e.target as HTMLDivElement;
    if (target) {
      const currentScrollPosition = target.scrollTop;
      this.blurBottom = currentScrollPosition < target.scrollHeight - target.offsetHeight;
      this.blurTop = currentScrollPosition !== 0;

      if (!this.isMobile) {
        if (currentScrollPosition <= 0) {
          this.showUpload = true;
          this.lastScrollPosition = 0;
          return;
        }
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < scrollBuffer) {
          return;
        }

        this.showUpload = currentScrollPosition < this.lastScrollPosition;
        this.lastScrollPosition = currentScrollPosition;
      } else {
        let newButtonPosition = this.lastButtonPosition + (this.lastScrollPosition - currentScrollPosition);
        const hiddenPosition = -1 * (rowHeight + borderBuffer);
        if (Math.abs(newButtonPosition) < rowHeight + borderBuffer) {
          newButtonPosition = newButtonPosition >= 0 ? 0 : newButtonPosition;
          this.$refs.uploader.$el.setAttribute('style', `top: ${newButtonPosition}px;`);
          this.lastButtonPosition = newButtonPosition;
        } else if (this.lastButtonPosition !== hiddenPosition && this.lastButtonPosition !== 0) {
          this.$refs.uploader.$el.setAttribute('style', `top: ${hiddenPosition}px;`);
          this.lastButtonPosition = hiddenPosition;
        }
        this.lastScrollPosition = currentScrollPosition;
      }
    }
  }

  isSongSelected(song: UserAudio): boolean {
    return this.newSelectedAudio.length > 0 && song.id === this.newSelectedAudio[0].id;
  }

  login() {
    this.$refs.modal.hide('login');
    this.$root.$emit('bv::show::modal', 'login-modal');
  }

  playNewAudio(audio: UserAudio) {
    if (this.audioPlayer && !this.audioPlayer.player.paused) {
      this.audioPlayer.player.pause();
      this.playingAudioID = '';
    }
    this.audioPlayer = {
      id: audio.id,
      player: new Audio(audio.url),
    };
    this.audioPlayer.player.onended = () => {
      this.playingAudioID = '';
    };
    this.audioPlayer.player.play();
    this.playingAudioID = audio.id;
  }

  playPauseAudio(audio: UserAudio) {
    if (this.audioPlayer) {
      const { player } = this.audioPlayer;

      if (this.audioPlayer.id === audio.id) {
        if (!player.paused) {
          player.pause();
          this.playingAudioID = '';
        } else {
          player.play();
          this.playingAudioID = audio.id;
        }
      } else {
        this.playNewAudio(audio);
      }
    } else {
      this.playNewAudio(audio);
    }
  }

  songName(song: string) {
    if (song.length < truncateLength) {
      return song;
    }
    return `${song.substring(0, truncateLength)}...`;
  }

  uploadAudio(file: File) {
    this.$refs.modal.hide();
    this.$emit('upload', file);
  }
}
