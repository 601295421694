


























import {
  Component, Model, Prop, Vue,
} from 'vue-property-decorator';

@Component({})
export default class DesktopPager extends Vue {
  @Model('input', { type: Number })
  readonly index: number;

  @Prop({ required: true }) count: number;

  @Prop({ default: 1 }) step: number;

  @Prop({ default: false }) disabled: boolean;

  get displayIndex() {
    return Math.ceil(this.index / this.step) + 1;
  }

  get displayCount() {
    return Math.ceil(this.count / this.step);
  }
}
