























































































































import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import WaveSurfer from 'wavesurfer.js';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
// @ts-ignore
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min';

import { isCanceled } from '@/shared/firebase';
import defaultToast from '@/shared/lib/defaultToast';
import { Operation_Status as OperationStatus, UserAudio, UserAudioSource } from '@/shared/gen/messages.pisa';
import { ErrorDetailTooShort, ErrorDetailTooLong } from '@/shared/store/audioLibrary';

import AudioUploadProgressComponent from '@/shared/components/common/AudioUploadProgressComponent.vue';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import { FileExceedsMaximumAllowedSizeError } from '@/shared/store/upload';
import { maxAudioFileSizeInMb } from '@/shared/lib/defaultFileConstraints';
import MaterialInputField from '@/shared/components/form/MaterialInputField.vue';
import SimpleModal from '@/shared/components/modals/SimpleModal.vue';
import ModalButton from '@/shared/components/modals/ModalButton.vue';

@Component({
  mixins: [
    validationMixin,
  ],
  components: {
    ModalButton,
    SimpleModal,
    AudioUploadProgressComponent,
    LoadingIndicator,
    MaterialInputField,
  },
  validations: {
    songName: { required },
  },
})
export default class AudioUploadModal extends Vue {
  $refs!: {
    modal: SimpleModal,
    songNameInput: any,
  };

  @Getter('upload/activeUploads') currentUploads: string[];

  @Getter('upload/uploadFileName') uploadFileNameFn: (id: string) => string;

  @Getter('upload/uploadPercentage') uploadPercentageFn: (id: string) => number;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  uploadID: string = '';

  songName: string = '';

  wavesurfer: any = null;

  saving: boolean = false;

  previewPlaying: boolean = false;

  source: UserAudioSource | null = null;

  startTimeMs: number = -1;

  get fileAttachedToDraft() {
    return !!this.source;
  }

  get uploadFileName(): string {
    return this.uploadFileNameFn(this.currentUploads[0]);
  }

  get uploadPercentage(): number {
    return this.uploadPercentageFn(this.currentUploads[0]);
  }

  delayTouchUntilBlur($v: any) {
    $v.$reset();
  }

  touchElement($v: any) {
    $v.$touch();
  }

  showModal(file?: File) {
    if (file) {
      Vue.toasted.clear();
      this.$refs.modal.show();
      this.uploadAudio(file);
    }
  }

  uploadAudio(file: File) {
    let localUploadID: string;
    this.$store.dispatch('audioLibrary/upload', file).then(([id, promise]) => {
      localUploadID = id;
      this.uploadID = id;
      return promise;
    }).then((source: UserAudioSource) => {
      if (localUploadID !== this.uploadID) {
        return;
      }
      this.source = source;
      if (source.name) {
        this.songName = source.name;
      }
      this.startTimeMs = -1;
      this.$nextTick(() => {
        this.renderWaveform(source);
      });
    }).catch((reason) => {
      if (localUploadID !== this.uploadID) {
        return;
      }
      if (isCanceled(reason)) {
        return;
      }
      let errMsg = 'Failed to upload song.';
      if (reason === FileExceedsMaximumAllowedSizeError) {
        errMsg = `Song files cannot be larger than ${maxAudioFileSizeInMb} MB`;
      } else if (reason && reason.operation && reason.operation.status === OperationStatus.ERROR) {
        if (reason.operation.detail === ErrorDetailTooShort) {
          errMsg = 'Songs can not be shorter than 30 seconds.';
        } else if (reason.operation.detail === ErrorDetailTooLong) {
          errMsg = 'Songs can not be longer than 10 minutes.';
        } else {
          // TODO: Show error because audio was an unknown/invalid format...
        }
      }
      defaultToast(errMsg);
      this.$refs.modal.hide();
    });
  }

  renderWaveform(audio: any) {
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      progressColor: '#42FFD6',
      height: 64,
      // maxCanvasWidth: 640,
      cursorColor: 'transparent',
      minPxPerSec: 10,
      pixelRatio: 1,
      normalize: true,
      interact: false,
      backend: 'MediaElement',
      responsive: true,
      plugins: [
        RegionPlugin.create(),
      ],
    });

    this.wavesurfer.load(audio ? audio.url : '/SampleAudio_0.5mb.mp3');
    this.wavesurfer.on('ready', () => {
      this.$nextTick(() => {
        const startTime: number = this.startTimeMs === -1 ? (Math.round(audio.durationMs / 1000) / 2) - 15 : Math.round(this.startTimeMs / 1000);
        this.wavesurfer.addRegion({
          id: 'preview',
          start: startTime,
          end: startTime + 30,
          color: 'rgba(66, 255, 214, 0.5)',
          regionHighlight: true,
          drag: true,
          resize: false,
          attributes: { highlight: true },
        });
        this.wavesurfer.seekTo(this.selectedStartMs() / this.source!.durationMs);
        this.wavesurfer.regions.list.preview.on('update-end', () => {
          const playBtnElem = document.getElementById('playButton');
          if (playBtnElem) {
            playBtnElem.focus();
          }
        });
      });
    });
    this.wavesurfer.on('finish', () => {
      this.wavesurfer.setCursorColor('transparent');
      this.previewPlaying = false;
    });
    this.wavesurfer.on('pause', () => {
      // this.wavesurfer.setCursorColor('transparent');
      this.previewPlaying = false;
    });
    this.wavesurfer.on('audioprocess', () => {
      if (this.wavesurfer.regions && this.wavesurfer.regions.list && this.wavesurfer.regions.list.preview) {
        const { end } = this.wavesurfer.regions.list.preview;
        const currentTime = this.wavesurfer.getCurrentTime();
        if (end && currentTime && currentTime >= end) {
          this.wavesurfer.pause();
          this.wavesurfer.seekTo(this.selectedStartMs() / this.source!.durationMs);
        }
      }
    });
    this.wavesurfer.on('region-updated', () => {
      this.wavesurfer.setCursorColor('transparent');
      this.wavesurfer.pause();
      this.wavesurfer.seekTo(this.selectedStartMs() / this.source!.durationMs);
    });
  }

  selectedStartMs() {
    if (this.wavesurfer && this.wavesurfer.regions && this.wavesurfer.regions.list.preview) {
      return Math.round(this.wavesurfer.regions.list.preview.start) * 1000;
    }
    return 0;
  }

  cancelUpload() {
    this.$store.dispatch('upload/cancelUpload');
    this.$emit('upload-canceled');
  }

  cancelUploadAndHide() {
    this.cancelUpload();
    this.$refs.modal.hide();
  }

  playAudio() {
    if (!this.previewPlaying) {
      if (this.wavesurfer.regions.list.preview) {
        this.wavesurfer.setCursorColor('#FFFFFF');
        this.previewPlaying = true;
        const playPromise = this.wavesurfer.playPause();
        if (playPromise && typeof playPromise.then === 'function') {
          playPromise.then(null, () => {
            this.previewPlaying = false;
          });
        }
      }
    } else {
      this.previewPlaying = false;
      const playPromise = this.wavesurfer.playPause();
      if (playPromise && typeof playPromise.then === 'function') {
        playPromise.then(null, () => {
          this.previewPlaying = false;
        });
      }
    }
  }

  deleteAudio() {
    this.$store.dispatch('audioLibrary/deleteAudio', this.source).then(() => {
      this.source = null;
    });
  }

  saveAudio() {
    if (this.fileAttachedToDraft && this.songName) {
      this.saving = true;
      this.source!.name = this.songName;
      this.$store.dispatch('audioLibrary/updateAudio', this.source).then(() => {
        const userAudio = new UserAudio({
          source: this.source!,
          startMs: this.selectedStartMs(),
          durationMs: Math.min(this.source!.durationMs, 30000),
        });
        return this.$store.dispatch('onboarding/createPreview', userAudio).then(() => {
          this.$emit('created');
        });
      }).finally(() => {
        this.source = null;
        this.$refs.modal.hide();
      });
    }
  }

  reset() {
    if (this.currentUploads.length > 0) {
      this.cancelUpload();
    }
    if (this.source) {
      this.deleteAudio();
    }
    this.removeWavesurfer();
    this.uploadID = '';
    this.songName = '';
    this.saving = false;
    this.source = null;
    this.startTimeMs = -1;
    this.$v.$reset();
  }

  removeWavesurfer() {
    const { wavesurfer } = this;
    if (wavesurfer && wavesurfer.regions && wavesurfer.regions.list && wavesurfer.regions.preview && wavesurfer.regions.preview.remove) {
      wavesurfer.regions.list.preview.remove();
    }
  }
}
