

















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { MemberImage, UserAudio, UserImage } from '@/shared/gen/messages.pisa';
import { Draft } from '@/shared/store/onboarding';
import { ImageLibrarySelected } from '@/shared/store/recommended_ads';

import AudioUploadModal from '@/shared/components/common/AudioUploadModal.vue';
import ImageLibraryModal from '@/shared/components/builder/ImageLibraryModal.vue';
import ImageOverlay from '@/shared/components/builder/ImageOverlay.vue';
import LoadingIndicator from '@/shared/components/campaign/LoadingIndicator.vue';
import OpenImageLibraryButton from '@/shared/components/builder/OpenImageLibraryButton.vue';
import OpenSongLibraryButton from '@/shared/components/builder/OpenSongLibraryButton.vue';
import SocialConnections from '@/shared/components/common/SocialConnections.vue';
import SongLibraryModal from '@/shared/components/builder/SongLibraryModal.vue';

const releaseTruncateLength: number = 20;

@Component({
  mixins: [],
  components: {
    AudioUploadModal,
    ImageLibraryModal,
    ImageOverlay,
    LoadingIndicator,
    OpenImageLibraryButton,
    OpenSongLibraryButton,
    SocialConnections,
    SongLibraryModal,
  },
  validations: {},
})
export default class CampaignInfo extends Vue {
  $refs!: {
    audioUpload: AudioUploadModal,
  }

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  @Getter('onboarding/getDraftAudio') selectedAudio: UserAudio[];

  @Getter('onboarding/getDraftImages') selectedImages: UserImage[];

  @Getter('onboarding/getDraftMemberImages') selectedMemberImages: MemberImage[];

  @Getter('onboarding/getFacebookPage') syncedPage: any;

  @Prop({ default: null })
  draft: Draft;

  @Prop({ default: '' })
  artistName: string;

  @Prop({ default: '' })
  releaseName: string;

  @Prop({ default: '' })
  releaseType: string;

  @Prop({ default: () => [] })
  audioLists: [];

  @Prop({ default: false })
  hasExistingFacebookConnection: boolean;

  get artistTruncateLength() {
    return this.isMobile ? 15 : 20;
  }

  get imageURL() {
    if (this.draft.landingPages[0] && this.draft.landingPages[0].ogImageUrl) {
      return this.draft.landingPages[0].ogImageUrl;
    }
    return this.draft.imageURL;
  }

  get releaseDetailsReleaseName() {
    if (this.releaseName.length < releaseTruncateLength) {
      return this.releaseName;
    }
    return `${this.releaseName.substring(0, releaseTruncateLength)}...`;
  }

  get releaseDetailsArtistName() {
    if (this.artistName.length < this.artistTruncateLength) {
      return this.artistName;
    }
    return `${this.artistName.substring(0, this.artistTruncateLength)}...`;
  }

  openAudioUploadModal(file: File) {
    this.$refs.audioUpload.showModal(file);
  }

  openImageLibraryModal(evt: Event) {
    evt.preventDefault();
    this.$root.$emit('bv::show::modal', 'image-upload-modal', evt.target);
  }

  openSongLibraryModal(evt: Event) {
    evt.preventDefault();
    this.$root.$emit('bv::show::modal', 'song-upload-modal', evt.target);
  }

  handleImagesSelected(images: ImageLibrarySelected) {
    const { selectedImages, selectedMemberImages } = images;
    const originalSelectedMemberImageIDs = this.selectedMemberImages.map((i) => i.id);
    const selectedMemberImageIDs = selectedMemberImages.map((i) => i.id);
    let added = selectedMemberImageIDs.some((id) => originalSelectedMemberImageIDs.indexOf(id) === -1);
    let removed = originalSelectedMemberImageIDs.some((id) => selectedMemberImageIDs.indexOf(id) === -1);
    if (added || removed) {
      this.$store.dispatch('recommendedAds/userEditedMemberImages', true);
      this.$store.dispatch('mixpanel/track', {
        properties: {
          added,
          removed,
        },
        action: 'Zire.MemberImagesChanged',
      });
    }
    if (!added || !removed) {
      const originalSelectedImageIDs = this.selectedImages.map((i) => i.id);
      const selectedImageIDs = selectedImages.map((i) => i.id);
      added = added || selectedImageIDs.some((id) => originalSelectedImageIDs.indexOf(id) === -1);
      removed = removed || originalSelectedImageIDs.some((id) => selectedImageIDs.indexOf(id) === -1);
    }
    if (added || removed) {
      this.$root.$emit('bv::show::modal', 'updating-campaign-details-modal');
      Promise.all([
        this.$store.dispatch('onboarding/saveImagesToUGC', selectedImages),
        this.$store.dispatch('onboarding/saveMemberImagesToUGC', selectedMemberImages),
      ]).then(() => {
        this.$store.dispatch('onboarding/save').then(() => {
          this.$store.dispatch('creatives/refreshFocused');
          this.$emit('images-selected', { added, removed });
        });
      });
    }
  }

  handleSongChosen(selectedAudio: UserAudio[]) {
    if (this.selectedAudio.map((i) => i.id) !== selectedAudio.map((i) => i.id)) {
      this.$root.$emit('bv::show::modal', 'updating-campaign-details-modal');
      this.$store.dispatch('onboarding/saveUserAudioToUGC', selectedAudio).then(() => {
        this.$store.dispatch('onboarding/save').then(() => {
          this.$store.dispatch('creatives/refreshFocused');
          this.$emit('song-selected');
        });
      });
    }
  }

  uploadCanceled() {
    this.$root.$emit('bv::show::modal', 'song-upload-modal');
  }
}
