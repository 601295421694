














































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { validationMixin } from 'vuelidate';
import { BModal } from 'bootstrap-vue';
import BuilderLayout from '@/shared/components/builder/BuilderLayout.vue';
import { BUILDER_SOURCE_SEARCH, Draft } from '@/shared/store/onboarding';
import Card from '@/shared/components/stripe/Card.vue';
import RepeatCard from '@/shared/components/stripe/RepeatCard.vue';
import Signup from '@/shared/components/form/Signup.vue';
import { estimateImpressions, optionForDuration } from '@/shared/lib/budgetOptions';
import { ProcessingErrorMessage } from '@/shared/store/payment';
import {
  IApplyCouponResponse,
  IPaymentMethodResponse,
} from '@/shared/gen/messages.pisa';
import { getUGC } from '@/shared/lib/ugc_adaptor';
import LoadingIndicator from '@/shared/components/common/LoadingIndicator.vue';
import MusicIndustry from '@/shared/lib/music_industry';
import defaultErrorToast from '@/shared/lib/defaultToast';
import CouponForm from '@/shared/components/form/CouponForm.vue';

@Component({
  mixins: [validationMixin],
  components: {
    CouponForm,
    BuilderLayout,
    Card,
    RepeatCard,
    Signup,
    LoadingIndicator,
  },
})
export default class CheckoutStep extends Vue {
  $refs!: {
    signupForm: Signup,
    cardForm: Card,
    couponForm: CouponForm,
    loginModal: BModal,
  };

  bandlabOnly: boolean = false;

  loaded: boolean = false;

  summaryVisible: boolean = false;

  reachScale: number = 714.2857; // 50¢ / 70¢ CPM

  paymentProcessing: boolean = false;

  enteringCoupon: boolean = false;

  applyingCoupon: boolean = false;

  couponError: string = '';

  @Getter('payment/getErrors') getCheckoutErrors: ProcessingErrorMessage[];

  @Getter('profile/isNewAccount') isNewAccount: boolean;

  @Getter('profile/requiresEmailVerification') notVerified: boolean;

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Watch('isLoggedInWithEmail')
  reloadPayment(val: boolean, oldVal: boolean) {
    if (val !== oldVal && val) {
      this.loadPayment();
      if (this.couponCode.length > 0) {
        this.applyCoupon(this.couponCode).catch((err) => {
          this.clearCoupon();
          defaultErrorToast(this.messageForReason(err.message));
        });
      }
    }
  }

  @Getter('onboarding/getCouponCode') couponCode: string;

  @Getter('payment/appliedCoupon') appliedCoupon: IApplyCouponResponse;

  get showCouponForm() {
    return this.appliedCoupon.code || this.enteringCoupon || this.couponCode;
  }

  get paymentRequired() {
    return this.totalAmountCents > 0;
  }

  get discountText() {
    return this.appliedCoupon.name;
  }

  get discountCurrency() {
    if (this.appliedCoupon.amountOff) {
      return this.appliedCoupon.currency;
    }
    return this.draft.currency;
  }

  get discountAmount() {
    let amount = 0;
    if (this.appliedCoupon.amountOff) {
      amount = this.appliedCoupon.amountOff;
    }
    if (this.appliedCoupon.percentOff) {
      amount = (this.draft.budgetCents * this.appliedCoupon.percentOff) / 100;
    }
    return Math.min(amount, this.draft.budgetCents);
  }

  get showAccountCreate() {
    return this.isNewAccount || !this.isLoggedInWithEmail || this.notVerified;
  }

  getDraft(): Draft {
    return { ...this.draftState };
  }

  beforeCreate() {
    this.$store.dispatch('industry/loadConfigIfNecessary').then(() => {
      let id: string = '0';
      if (this.$route.params.id) {
        ({ id } = this.$route.params);
      } else if (this.getDraft) {
        this.draft = this.getDraft();
        if (this.draft.id) {
          ({ id } = this.draft);
        }
      }
      this.$store.dispatch('onboarding/load', id).finally(() => {
        this.draft = this.getDraft();
        let params = {};
        if (this.draft.id) {
          params = {
            id: this.draft.id,
          };
        }
        if (MusicIndustry.isValid(this.draft, 'checkout', params)) {
          this.loadPayment().then(() => {
            const numberUserImagesApplied = this.$store.getters['onboarding/getNumDraftImages'];
            const numberStockImagesApplied = this.$store.getters['onboarding/getNumDraftMemberImages'];
            const creativeControlMixpanelData = this.$store.getters['recommendedAds/getCreativeControlMixpanelData'];
            this.$store.dispatch('mixpanel/trackOnce', {
              properties: {
                releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
                releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
                domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
                onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
                numberUserImagesApplied,
                numberStockImagesApplied,
                numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
                numberUserCopyEditsApplied: creativeControlMixpanelData.numberUserCopyEditsApplied,
                numberUserDisabledAds: creativeControlMixpanelData.numberUserDisabledAds,
                numberIndividuallyEditedAds: creativeControlMixpanelData.numberAdsEdited + creativeControlMixpanelData.numberUserDisabledAds,
                numberEnabledLocalAds: creativeControlMixpanelData.numberEnabledLocalAds,
                numberEnabledPlayableAds: creativeControlMixpanelData.numberEnabledPlayableAds,
                numberEnabledFeedbackAds: creativeControlMixpanelData.numberEnabledFeedbackAds,
                numberEnabledStreamingAds: creativeControlMixpanelData.numberEnabledStreamingAds,
                numberEnabledFBAds: creativeControlMixpanelData.numberEnabledFBAds,
                numberEnabledIGAds: creativeControlMixpanelData.numberEnabledIGAds,
                creativeControlEditsMade: creativeControlMixpanelData.creativeControlEditsMade,
                hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
                appliedBudget: this.draft.budgetCents / 100,
                appliedDurationDays: this.draft.durationDays,
                presavedPaymentInfo: !!(this.repeatPayment && this.repeatPayment.last4),
                accessedThrough: this.$store.getters['onboarding/getAccessedThrough'],
                referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
                appliedAdNetworks: this.$store.getters['onboarding/getAdNetworks'],
              },
              action: 'Zire.CheckoutStepStarted',
            });
          });
        } else {
          const redirect = MusicIndustry.getAppropriateStep(this.draft, 'checkout', params);
          this.$router.replace(redirect);
        }
      });
    });
  }

  mounted() {
    if (this.$route.query?.token) {
      this.$store.dispatch('profile/logInWithBLToken', { token: this.$route.query?.token });
    }

    if (this.couponCode && !this.appliedCoupon.code) {
      this.applyCoupon(this.couponCode);
      if (this.isTouch) {
        this.summaryVisible = true;
      }
    }
  }

  destroyed() {
    this.$store.dispatch('payment/clearCoupon');
  }

  draft: Draft = this.$store.getters['onboarding/getDraft'];

  @Getter('payment/getPayment') repeatPayment: IPaymentMethodResponse;

  @Getter('layout/isTouch') isTouch: boolean;

  @Getter('onboarding/getValidAdNetworks') adNetworks: string[];

  reusePaymentInformation: boolean = false;

  hasExistingPaymentInformation: boolean = false;

  submitDisabled: boolean = false;

  checkoutErrors: ProcessingErrorMessage[] = [];

  get isProcessing() {
    return this.$store.getters['onboarding/isProcessing'];
  }

  get draftState() {
    return this.$store.getters['onboarding/getDraft'];
  }

  get expectedImpressions() {
    return estimateImpressions(this.logarithmicBudget);
  }

  get logarithmicBudget() {
    return this.draft.budgetCents / 100;
  }

  get hasDisplay() {
    return this.adNetworks.indexOf('display') !== -1;
  }

  get hasFacebook() {
    return this.adNetworks.indexOf('facebook') !== -1;
  }

  get hasInstagram() {
    return this.adNetworks.indexOf('instagram') !== -1;
  }

  get displayBudget() {
    return { amount: this.draft.budgetCents, currency: this.draft.currency };
  }

  get displayDiscount() {
    return { amount: -this.discountAmount, currency: this.discountCurrency };
  }

  get totalAmountCents() {
    return Math.max(0, this.draft.budgetCents - this.discountAmount);
  }

  get displayTotal() {
    if (this.appliedCoupon.code) {
      return {
        amount: this.totalAmountCents,
        currency: this.discountCurrency,
      };
    }
    return this.displayBudget;
  }

  get campaignDuration() {
    const option = optionForDuration(this.draft.durationDays);
    if (option) {
      return option.dayDuration;
    }
    return null;
  }

  get campaignDisplayDuration() {
    if (this.draft.durationDays === 7) {
      return '1 week';
    }
    if (this.draft.durationDays === 30) {
      return '1 month';
    }
    return '3 months';
    // const option = optionForDuration(this.draft.durationDays);
    // if (option) {
    //   return option.duration;
    // }
    // return null;
  }

  get artistName() {
    return getUGC('artistName');
  }

  get releaseName() {
    return getUGC('releaseName');
  }

  get releaseType() {
    return getUGC('releaseType');
  }

  get inlineCardError() {
    return this.$refs.cardForm && (this.$refs.cardForm.cardError || this.$refs.cardForm.expiryError || this.$refs.cardForm.cvcError);
  }

  toggleReusePayment() {
    this.reusePaymentInformation = !this.reusePaymentInformation;
    this.$refs.cardForm.focusNumber();
  }

  get displayCheckoutError() {
    if (this.checkoutErrors[0] && this.checkoutErrors[0].message) {
      return this.checkoutErrors[0].message;
    }
    return 'An error occurred while processing your card. Please review your payment details and try again.';
  }

  get cardExpired() {
    if (Object.keys(this.repeatPayment).length === 0) {
      return false;
    } if (this.repeatPayment && this.repeatPayment.expYear && this.repeatPayment.expMonth) {
      const currentDate = new Date();
      return this.repeatPayment.expYear === currentDate.getFullYear() && this.repeatPayment.expMonth < (currentDate.getMonth() + 1);
    }
    return false;
  }

  get cancelFee() {
    return { amount: Math.min(5000, this.draft.budgetCents / 10), currency: this.draft.currency };
  }

  get complete() {
    return (this.totalAmountCents === 0 || this.$refs.cardForm.complete || this.reusePaymentInformation)
      && (this.isLoggedInWithEmail || !this.$refs.signupForm.hasError);
  }

  loadPayment() {
    return new Promise<void>((resolve) => {
      this.$store.dispatch('payment/load').then(() => {
        if (this.getCheckoutErrors.length === 0) {
          if (this.repeatPayment && this.repeatPayment.last4 && this.repeatPayment.expYear && this.repeatPayment.expMonth) {
            const repeatPaymentExpiration = new Date(this.repeatPayment.expYear, this.repeatPayment.expMonth, -1);
            if (repeatPaymentExpiration.getTime() >= Date.now()) {
              this.reusePaymentInformation = true;
              this.hasExistingPaymentInformation = true;
            }
          }
        }
        this.loaded = true;
        resolve();
      });
    });
  }

  submitPayment() {
    const numberUserImagesApplied = this.$store.getters['onboarding/getNumDraftImages'];
    const numberStockImagesApplied = this.$store.getters['onboarding/getNumDraftMemberImages'];
    const creativeControlMixpanelData = this.$store.getters['recommendedAds/getCreativeControlMixpanelData'];
    const checkoutStepProperties = {
      releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
      releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
      domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
      onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
      numberUserImagesApplied,
      numberStockImagesApplied,
      numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
      numberUserCopyEditsApplied: creativeControlMixpanelData.numberUserCopyEditsApplied,
      numberUserDisabledAds: creativeControlMixpanelData.numberUserDisabledAds,
      numberIndividuallyEditedAds: creativeControlMixpanelData.numberAdsEdited + creativeControlMixpanelData.numberUserDisabledAds,
      numberEnabledLocalAds: creativeControlMixpanelData.numberEnabledLocalAds,
      numberEnabledPlayableAds: creativeControlMixpanelData.numberEnabledPlayableAds,
      numberEnabledFeedbackAds: creativeControlMixpanelData.numberEnabledFeedbackAds,
      numberEnabledStreamingAds: creativeControlMixpanelData.numberEnabledStreamingAds,
      numberEnabledFBAds: creativeControlMixpanelData.numberEnabledFBAds,
      numberEnabledIGAds: creativeControlMixpanelData.numberEnabledIGAds,
      creativeControlEditsMade: creativeControlMixpanelData.creativeControlEditsMade,
      hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
      appliedBudget: this.draft.budgetCents / 100,
      appliedDurationDays: this.draft.durationDays,
      presavedPaymentInfo: this.reusePaymentInformation,
      accessedThrough: this.$store.getters['onboarding/getAccessedThrough'],
      referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
      amountPaid: this.displayTotal.amount / 100,
      discountAmount: this.discountAmount / 100,
      appliedAdNetworks: this.$store.getters['onboarding/getAdNetworks'],
    };

    if (this.totalAmountCents === 0 || this.reusePaymentInformation) {
      this.$store.dispatch('payment/checkout', this.appliedCoupon.code).then(() => {
        this.$store.dispatch('mixpanel/trackOnce', { properties: checkoutStepProperties, action: 'Zire.CheckoutStepCompleted' });
        const receiptId = this.$store.getters['campaign/campaignDetails'].id;
        const path = `/builder/receipt/${receiptId}`;
        this.$router.push(path);
        this.submitDisabled = false;
      }, () => {
        this.trackCheckoutStepFailure(checkoutStepProperties, 'payment_checkout_failure');
        this.checkoutErrors = this.getCheckoutErrors;
        this.submitDisabled = false;
        this.paymentProcessing = false;
      });
    } else if (!this.inlineCardError) {
      this.$store.dispatch('payment/save', { elements: this.$refs.cardForm.card, savePayment: this.$refs.cardForm.savePayment === 'true' }).then(() => {
        this.$store.dispatch('payment/checkout', this.appliedCoupon.code).then(() => {
          this.$store.dispatch('mixpanel/trackOnce', { properties: checkoutStepProperties, action: 'Zire.CheckoutStepCompleted' });
          const receiptId = this.$store.getters['campaign/campaignDetails'].id;
          const path = `/builder/receipt/${receiptId}`;
          this.$router.push(path);
          this.submitDisabled = false;
        }, () => {
          this.trackCheckoutStepFailure(checkoutStepProperties, 'payment_checkout_failure');
          this.checkoutErrors = this.getCheckoutErrors;
          this.submitDisabled = false;
          this.paymentProcessing = false;
        });
      }, () => {
        this.trackCheckoutStepFailure(checkoutStepProperties, 'payment_save_failure');
        this.checkoutErrors = this.getCheckoutErrors;
        this.submitDisabled = false;
        this.paymentProcessing = false;
      });
    } else {
      this.trackCheckoutStepFailure(checkoutStepProperties, 'user_input_validation');
      this.checkoutErrors = this.getCheckoutErrors;
      this.submitDisabled = false;
      this.paymentProcessing = false;
    }
  }

  trackCheckoutStepFailure(properties: any, reason: string) {
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: { ...properties, reason },
      action: 'Zire.CheckoutStepFailure',
    });
  }

  beginEnteringMobile() {
    this.summaryVisible = true;
    this.$nextTick(() => {
      this.beginEntering();
    });
  }

  beginEntering() {
    this.enteringCoupon = true;
    this.$nextTick(() => {
      this.$refs.couponForm.focus();
    });
  }

  applyCoupon(code: string) {
    this.enteringCoupon = true;
    this.applyingCoupon = true;
    return this.$store.dispatch('payment/applyCoupon', { campaignId: this.draft.id, code })
      .then(() => {
        this.draft = this.getDraft();
        this.$store.dispatch('mixpanel/track', {
          properties: {
            discountCode: this.couponCode,
          },
          action: 'Zire.DiscountCodeApplied',
        });
      }).catch((err) => {
        this.couponError = this.messageForReason(err.message);
        throw err;
      }).finally(() => {
        this.applyingCoupon = false;
      });
  }

  messageForReason(reason: string) {
    if (reason === 'invalid') {
      return 'The code you entered is invalid.';
    } if (reason === 'expired') {
      return 'The code you entered is expired.';
    } if (reason === 'not_applicable') {
      return 'You don\'t qualify for this coupon code.';
    } if (reason === 'redemptions_reached') {
      return 'This code has been used its maximum number of times.';
    } if (reason === 'user_redemptions_reached') {
      return 'You don\'t qualify for any more uses of this code.';
    }
    return 'Something went wrong';
  }

  clearCoupon() {
    this.enteringCoupon = false;
    this.couponError = '';
    this.$store.dispatch('payment/clearCoupon').then(() => {
      this.$refs.couponForm.clear();
      this.draft = this.getDraft();
    });
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.checkoutErrors = [];
    if (this.$refs.signupForm) {
      this.$refs.signupForm.$v.$touch();
      this.$refs.signupForm.submitted = true;
    }
    if (!this.complete) {
      if (this.totalAmountCents) {
        this.$refs.cardForm.submitted = true;
      }
      return;
    }
    this.submitDisabled = true;
    this.paymentProcessing = true;
    if (this.isLoggedInWithEmail) {
      this.submitPayment();
    } else {
      if (this.totalAmountCents) {
        this.$refs.cardForm.submitted = true;
      }

      const signup = this.$refs.signupForm;
      signup.emailInUse = false;
      this.$store.dispatch('profile/signUp', { email: signup.newEmail, password: signup.pwd, consents: signup.consents })
        .then(() => {
          this.submitPayment();
        })
        .catch((error) => {
          this.submitDisabled = false;
          this.paymentProcessing = false;
          if (error.code === 'auth/email-already-in-use') {
            signup.emailInUse = true;
            this.focusAlert();
          } else {
            defaultErrorToast(error.message);
          }
        });
    }
  }

  focusAlert() {
    const elem = document.getElementById('create-account-title');
    if (elem) {
      elem.scrollIntoView();
    }
  }

  onBack(evt: Event) {
    evt.preventDefault();
    let path = '/builder/budget';
    if (this.$route.params.id) {
      path = `/builder/budget/${this.$route.params.id}`;
    }
    this.$router.push(path).then(() => {
      this.$store.dispatch('onboarding/setCouponCode', this.couponCode);
    });
  }

  login() {
    this.$root.$emit('bv::show::modal', 'login-modal');
  }

  triggerTooltipMobile() {
    const elem = document.getElementById('disclaimer_mobile');
    if (elem) {
      elem.focus();
    }
  }
}
