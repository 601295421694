










































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { FullNetworkOption } from '@/shared/store/industry';

@Component({})
export default class CampaignNetworksComponent extends Vue {
  @Prop({ required: true })
  value: string[];

  @Prop({ default: () => [] })
  networks: FullNetworkOption[];

  @Getter('layout/isTablet') isTablet: boolean;

  get selectedNetworks() {
    return this.value;
  }

  set selectedNetworks(value) {
    this.$emit('input', value);
  }

  get displayedNetworks() {
    return this.networks;
  }
}
