








import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component({})
export default class PlayPauseButton extends Vue {
  @Prop({ required: true })
  audioId: string;

  @Prop({ default: 'play-pause-button' })
  buttonClass: string;

  @Prop({ required: true })
  playingId: string;

  get icon() {
    return this.audioId === this.playingId ? ['fas', 'pause'] : ['fas', 'play'];
  }
}
