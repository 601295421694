























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Draft } from '@/shared/store/onboarding';
import { AlbumResult } from '@/shared/store/retailerSearch';
import StandardLayout from '@/shared/components/common/StandardLayout.vue';
import StepDescription from '@/shared/components/builder/StepDescription.vue';
import BuilderNavigation from '@/shared/components/builder/BuilderNavigation.vue';
import LogoLink from '@/shared/components/site/LogoLink.vue';
import BackToBandlab from '@/shared/components/site/BackToBandlab.vue';

@Component({
  mixins: [],
  components: {
    StepDescription,
    StandardLayout,
    BuilderNavigation,
    LogoLink,
    BackToBandlab,
  },
  props: {
    layoutClass: String,
    onSubmit: Function,
    submitDisabled: Boolean,
    onBack: Function,
    isProcessing: Boolean,
    buttonVariant: String,
    extraInfo: String,
    noBottomNav: Boolean,
  },
})
export default class BuilderLayout extends Vue {
  backlink: string = '';

  @Getter('onboarding/getDraft') draftState: Draft;

  @Getter('retailerSearch/getSelectedResult') selectedResult: AlbumResult;

  extractUrl(arr: Draft | AlbumResult): string {
    return arr?.landingPages.find((page) => page?.retailer === 'bandlab')?.url || '';
  }

  beforeMount() {
    if (this.draftState) {
      this.backlink = this.extractUrl(this.draftState);
    }
  }

  @Watch('selectedResult')
  updateFromSelected() {
    if (!this.backlink) {
      this.backlink = this.extractUrl(this.selectedResult);
    }
  }

  @Watch('draftState')
  updateFromDraft() {
    if (!this.backlink) {
      this.backlink = this.extractUrl(this.draftState);
    }
  }

  resetDraft() {
    this.$store.dispatch('onboarding/resetDraft');
  }
}
