





















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import EditAdModal from '@/shared/components/builder/EditAdModal.vue';
import ChangeImageSelectionModal from '@/shared/components/builder/changeImageSelectionModal.vue';
import {
  Creative,
  FacebookPage,
  MemberImage,
  UserImage,
} from '@/shared/gen/messages.pisa';
import { Draft } from '@/shared/store/onboarding';
import { ImageConfigJSON } from '@/shared/store/recommended_ads';

@Component({
  components: {
    EditAdModal,
    ChangeImageSelectionModal,
  },
})
export default class CustomizeCreatives extends Vue {
  $refs!: {
    editAdModal: EditAdModal,
    ChangeImageSelectionModal: ChangeImageSelectionModal,
  };

  @Getter('onboarding/getDraft') draft: Draft;

  @Getter('imageLibrary/getImageByID') getUserImage: Function;

  @Getter('recommendedAds/getMemberImageById') getMemberImage: Function;

  @Getter('onboarding/getFacebookPage') facebookPage: FacebookPage;

  creativeToEdit: Creative | undefined = {} as Creative;

  strategyType: string = '';

  selectedUserImage: UserImage|null = null;

  selectedMemberImage: MemberImage|null = null;

  imageConfig: ImageConfigJSON|null = null;

  get defaultImageSelectionTab() {
    if (this.selectedMemberImage) {
      return 'zire-images-tab';
    }
    return 'uploaded-images-tab';
  }

  get userImageList() {
    if (this.selectedUserImage) {
      return [this.selectedUserImage];
    }
    return [];
  }

  get memberImageList() {
    if (this.selectedMemberImage) {
      return [this.selectedMemberImage];
    }
    return [];
  }

  editAd(creatives: Creative[], creativeId: string, strategyType: string) {
    this.creativeToEdit = creatives.find((creative) => creative.id === creativeId);
    this.strategyType = strategyType;
    const userImageId = this.creativeToEdit?.customization.userImageId;
    const memberImageId = this.creativeToEdit?.customization.memberImageId;
    if (userImageId && userImageId !== '0') {
      this.selectedUserImage = this.getUserImage(userImageId);
      this.selectedMemberImage = null;
    } else if (memberImageId) {
      this.selectedUserImage = null;
      this.selectedMemberImage = this.getMemberImage(memberImageId);
    }
    this.$root.$emit('bv::show::modal', 'edit-ad-modal');
  }

  clearCreative() {
    const userImageId = this.creativeToEdit?.customization.userImageId;
    const memberImageId = this.creativeToEdit?.customization.memberImageId;
    if (userImageId && userImageId !== '0') {
      this.selectedUserImage = this.getUserImage(userImageId);
      this.selectedMemberImage = null;
    } else if (memberImageId) {
      this.selectedUserImage = null;
      this.selectedMemberImage = this.getMemberImage(memberImageId);
    }
    this.imageConfig = null;
  }

  setImageChanges(imageLists: any) {
    const { memberImages, userImages } = imageLists;

    this.$store.dispatch('recommendedAds/getImageConfig', {
      campaignId: this.draft.id,
      creativeId: this.creativeToEdit ? this.creativeToEdit.id : 0,
      userImageId: userImages[0] ? userImages[0].id : 0,
      memberImageId: memberImages[0] ? memberImages[0].id : 0,
    }).then((imageConfig) => {
      if (this.creativeToEdit && userImages[0]) {
        [this.selectedUserImage] = userImages;
        this.selectedMemberImage = null;
      }
      if (this.creativeToEdit && memberImages[0]) {
        this.selectedUserImage = null;
        [this.selectedMemberImage] = memberImages;
      }
      this.imageConfig = imageConfig;
    });
  }

  handleCustomizedAd(creative: Creative) {
    this.$store.dispatch('recommendedAds/saveCreative', { creative, campaignId: this.draft.id }).then(() => {
      this.$store.dispatch('creatives/postMessage', { id: `creative:${creative.id}`, command: 'zire:reload' });
      this.$emit('customized');
    });
  }
}
