









































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { validationMixin } from 'vuelidate';
import GridLayout from '@/shared/components/builder/GridLayout.vue';
import { BUILDER_SOURCE_SEARCH, Draft } from '@/shared/store/onboarding';
import CampaignNetworksComponent from '@/shared/components/builder/CampaignNetworksComponent.vue';
import BudgetSlider from '@/shared/components/builder/BudgetSlider.vue';
import * as budgetOptions from '@/shared/lib/budgetOptions';
import MusicIndustry from '@/shared/lib/music_industry';
import { FullNetworkOption } from '@/shared/store/industry';
import { Ads } from '@/shared/store/recommended_ads';

const NETWORK_DISPLAY = 'display';
const NETWORK_FACEBOOK = 'facebook';
const NETWORK_INSTAGRAM = 'instagram';

@Component({
  mixins: [validationMixin],
  components: {
    GridLayout,
    BudgetSlider,
    CampaignNetworksComponent,
  },
})
export default class BudgetStep extends Vue {
  getDraft(): Draft {
    return { ...this.draftState };
  }

  beforeCreate() {
    this.$store.dispatch('industry/loadConfigIfNecessary').then(() => {
      let id: string = '0';
      if (this.$route.params.id) {
        ({ id } = this.$route.params);
      } else if (this.getDraft) {
        this.draft = this.getDraft();
        if (this.draft.id) {
          ({ id } = this.draft);
        }
      }
      this.stepValues.networks = this.networks;
      const promises = [
        this.$store.dispatch('onboarding/load', id),
        this.$store.dispatch('recommendedAds/get', { campaignId: id }),
      ];
      if (!this.hasSyncedPage && !this.hasSyncedPages) {
        promises.push(this.$store.dispatch('facebook/loadSyncedPages'));
      }
      Promise.all(promises).finally(() => {
        this.draft = this.getDraft();
        let params = {};
        if (this.draft.id) {
          params = {
            id: this.draft.id,
          };
        }
        if (MusicIndustry.isValid(this.draft, 'budget', params)) {
          if (this.draft.budgetCents) {
            if (this.draft.budgetCents < this.stepValues.budgetMinCents) {
              this.draft.budgetCents = this.stepValues.budgetMinCents;
            }
            if (this.draft.budgetCents > this.stepValues.budgetMaxCents) {
              this.draft.budgetCents = this.stepValues.budgetMaxCents;
            }
          }
          if (this.draft.durationDays) {
            this.stepValues.currentDuration = this.draft.durationDays;
          }
          this.stepValues.budgetAmtCents = this.draft.budgetCents;
          this.stepValues.currency = this.draft.currency;
          if (this.draft.adNetworks.length > 0) {
            this.stepValues.currentNetworks = this.draft.adNetworks;
          } else {
            this.stepValues.currentNetworks = [NETWORK_DISPLAY];
          }
          // TODO: FIXME: This is hacky...
          if (this.draft.budgetCents === budgetOptions.defaultBudgetCents && this.draft.durationDays === 7 && this.hasSyncedPage) {
            this.stepValues.currentNetworks = [NETWORK_DISPLAY, NETWORK_FACEBOOK, NETWORK_INSTAGRAM];
          }

          const fbDisabled = this.ads.facebook.every((i) => !i.customization.enabled);
          if (fbDisabled) {
            this.stepValues.networks = this.stepValues.networks.filter((i) => i.key !== NETWORK_FACEBOOK);
            this.stepValues.currentNetworks = this.stepValues.currentNetworks.filter((i) => i !== NETWORK_FACEBOOK);
          }
          const igDisabled = this.ads.instagram.every((i) => !i.customization.enabled);
          if (igDisabled) {
            this.stepValues.networks = this.stepValues.networks.filter((i) => i.key !== NETWORK_INSTAGRAM);
            this.stepValues.currentNetworks = this.stepValues.currentNetworks.filter((i) => i !== NETWORK_INSTAGRAM);
          }

          const numberUserImagesApplied = this.$store.getters['onboarding/getNumDraftImages'];
          const numberStockImagesApplied = this.$store.getters['onboarding/getNumDraftMemberImages'];
          const creativeControlMixpanelData = this.$store.getters['recommendedAds/getCreativeControlMixpanelData'];
          this.$store.dispatch('mixpanel/trackOnce', {
            properties: {
              releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
              releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
              domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
              onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
              numberUserImagesApplied,
              numberStockImagesApplied,
              numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
              numberUserCopyEditsApplied: creativeControlMixpanelData.numberUserCopyEditsApplied,
              numberUserDisabledAds: creativeControlMixpanelData.numberUserDisabledAds,
              numberIndividuallyEditedAds: creativeControlMixpanelData.numberAdsEdited + creativeControlMixpanelData.numberUserDisabledAds,
              numberEnabledLocalAds: creativeControlMixpanelData.numberEnabledLocalAds,
              numberEnabledPlayableAds: creativeControlMixpanelData.numberEnabledPlayableAds,
              numberEnabledFeedbackAds: creativeControlMixpanelData.numberEnabledFeedbackAds,
              numberEnabledStreamingAds: creativeControlMixpanelData.numberEnabledStreamingAds,
              numberEnabledFBAds: creativeControlMixpanelData.numberEnabledFBAds,
              numberEnabledIGAds: creativeControlMixpanelData.numberEnabledIGAds,
              creativeControlEditsMade: creativeControlMixpanelData.creativeControlEditsMade,
              hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
              appliedBudget: (this.stepValues.budgetAmtCents / 100),
              appliedDurationDays: this.stepValues.currentDuration,
              accessedThrough: this.$store.getters['onboarding/getAccessedThrough'],
              referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
              appliedAdNetworks: this.stepValues.currentNetworks,
            },
            action: 'Zire.BudgetStepStarted',
          });
          this.setBudgetAndDuration(this.stepValues.budgetAmtCents, this.stepValues.currentDuration);
          this.loaded = true;
        } else {
          const redirect = MusicIndustry.getAppropriateStep(this.draft, 'budget', params);
          this.$router.replace(redirect);
        }
      });
    });
  }

  draft: Draft = this.$store.getters['onboarding/getDraft'];

  loaded: boolean = false;

  loading: boolean = false;

  @Getter('industry/fullNetworkOptions') networks: FullNetworkOption[];

  stepValues = {
    budgetAmtCents: budgetOptions.defaultBudgetCents,
    budgetMinCents: budgetOptions.minimumBudgetCents,
    budgetMaxCents: budgetOptions.maximumBudgetCents,
    currentDuration: budgetOptions.defaultDurationDays,
    preferredDuration: budgetOptions.defaultDurationDays,
    options: budgetOptions.options,
    currency: budgetOptions.currency,
    networks: [] as FullNetworkOption[],
    currentNetworks: ['display'],
  };

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Getter('onboarding/getFacebookPage') syncedPage: any;

  @Getter('facebook/hasSyncedPages') hasSyncedPages: boolean;

  @Getter('recommendedAds/getAds') ads: Ads;

  get hasSyncedPage() {
    return this.syncedPage && this.syncedPage.pageId;
  }

  get supportsMultipleNetworks() {
    return this.stepValues.networks.length > 1;
  }

  get isProcessing() {
    return this.$store.getters['onboarding/isProcessing'];
  }

  get draftState() {
    return this.$store.getters['onboarding/getDraft'];
  }

  get selectedNetworks() {
    return this.stepValues.currentNetworks.map((i) => this.networks.find((network: any) => network.key === i)).filter((i) => i);
  }

  get budget() {
    return budgetOptions.convertFromCentsToWholeDollar(this.stepValues.budgetAmtCents);
  }

  set budget(value) {
    this.setBudgetAndDuration(budgetOptions.convertToCents(value), this.duration);
  }

  handleChangeDuration(value: number) {
    this.duration = value;
  }

  get duration() {
    return this.stepValues.currentDuration;
  }

  set duration(value) {
    this.setBudgetAndDuration(this.stepValues.budgetAmtCents, value);
  }

  setBudgetAndDuration(budgetCents: number, durationDays: number) {
    const minBudgetCents = Math.min(...this.selectedNetworks.map((i: any) => {
      const budgetRestrictionIndex = i.budgetRestrictions.findIndex((restriction: any) => restriction.durationDays === durationDays);
      return i.budgetRestrictions[budgetRestrictionIndex].minimum.amount;
    }));
    // Update data...
    this.stepValues.budgetAmtCents = budgetCents;
    if (budgetCents < minBudgetCents) {
      this.stepValues.budgetAmtCents = budgetOptions.convertToCents(budgetOptions.convertFromCentsToWholeDollar(minBudgetCents));
    }
    this.stepValues.currentDuration = durationDays;
    // Disable networks that are too expensive...
    this.stepValues.networks.forEach((network: any, i: number) => {
      const budgetRestrictionIndex = network.budgetRestrictions.findIndex((restriction: any) => restriction.durationDays === durationDays);
      const minimumValidBudget = network.budgetRestrictions[budgetRestrictionIndex].minimum.amount;
      this.stepValues.networks[i].disabled = (this.stepValues.budgetAmtCents < minimumValidBudget);
    });
  }

  get budgetMin() {
    const minBudgetCents = Math.min(...this.selectedNetworks.map((i: any) => {
      const budgetRestrictionIndex = i.budgetRestrictions.findIndex((restriction: any) => restriction.durationDays === this.duration);
      return i.budgetRestrictions[budgetRestrictionIndex].minimum.amount;
    }));
    return budgetOptions.convertFromCentsToWholeDollar(minBudgetCents);
  }

  get budgetMax() {
    return budgetOptions.convertFromCentsToWholeDollar(this.stepValues.budgetMaxCents);
  }

  get validOptions() {
    return this.stepValues.options.filter((option) => this.stepValues.budgetAmtCents >= option.minimumBudgetCents
      && this.stepValues.budgetAmtCents <= this.stepValues.budgetMaxCents);
  }

  get submitDisabled() {
    return this.validOptions.length === 0 || this.loading;
  }

  onSubmit(evt: Event) {
    this.loading = true;
    evt.preventDefault();
    this.$store.dispatch('onboarding/setBudget', {
      budgetCents: this.stepValues.budgetAmtCents,
      currency: this.stepValues.currency,
      durationDays: this.stepValues.currentDuration,
      adNetworks: this.stepValues.currentNetworks,
    });
    const numberUserImagesApplied = this.$store.getters['onboarding/getNumDraftImages'];
    const numberStockImagesApplied = this.$store.getters['onboarding/getNumDraftMemberImages'];
    const creativeControlMixpanelData = this.$store.getters['recommendedAds/getCreativeControlMixpanelData'];
    this.$store.dispatch('onboarding/save').then(() => {
      this.$store.dispatch('mixpanel/trackOnce', {
        properties: {
          releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
          releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
          domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
          onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
          numberUserImagesApplied,
          numberStockImagesApplied,
          numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
          numberUserCopyEditsApplied: creativeControlMixpanelData.numberUserCopyEditsApplied,
          numberUserDisabledAds: creativeControlMixpanelData.numberUserDisabledAds,
          numberIndividuallyEditedAds: creativeControlMixpanelData.numberAdsEdited + creativeControlMixpanelData.numberUserDisabledAds,
          numberEnabledLocalAds: creativeControlMixpanelData.numberEnabledLocalAds,
          numberEnabledPlayableAds: creativeControlMixpanelData.numberEnabledPlayableAds,
          numberEnabledFeedbackAds: creativeControlMixpanelData.numberEnabledFeedbackAds,
          numberEnabledStreamingAds: creativeControlMixpanelData.numberEnabledStreamingAds,
          numberEnabledFBAds: creativeControlMixpanelData.numberEnabledFBAds,
          numberEnabledIGAds: creativeControlMixpanelData.numberEnabledIGAds,
          creativeControlEditsMade: creativeControlMixpanelData.creativeControlEditsMade,
          hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
          appliedBudget: this.stepValues.budgetAmtCents / 100,
          appliedDurationDays: this.duration,
          accessedThrough: this.$store.getters['onboarding/getAccessedThrough'],
          referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
          appliedAdNetworks: this.$store.getters['onboarding/getAdNetworks'],
        },
        action: 'Zire.BudgetStepCompleted',
      });

      let path = '/builder/checkout';
      if (this.$route.params.id) {
        path = `/builder/checkout/${this.$route.params.id}`;
      }
      this.$router.push(path);
      this.loading = false;
    }).catch(() => {
      // error has already been displayed by onboarding.ts
    });
  }

  onBack(evt: Event) {
    evt.preventDefault();
    this.$router.push({
      name: 'recommended-ads',
      params: {
        id: this.draft.id,
      },
    });
  }

  @Watch('syncedPage')
  watchSyncedPage(newVal: any, oldVal: any) {
    if (newVal && newVal.pageId && !(oldVal || {}).pageId) {
      this.stepValues.currentNetworks = [NETWORK_DISPLAY, NETWORK_FACEBOOK, NETWORK_INSTAGRAM];
      let newBudgetCents = this.stepValues.budgetAmtCents;
      this.stepValues.currentNetworks.forEach((key) => {
        const network = this.networks.find((i: any) => i.key === key);
        if (network) {
          const budgetRestrictionIndex = network.budgetRestrictions.findIndex((restriction: any) => restriction.durationDays === this.duration);
          const networkMinBudgetCents = network.budgetRestrictions[budgetRestrictionIndex].minimum.amount;

          if (newBudgetCents < networkMinBudgetCents) {
            newBudgetCents = networkMinBudgetCents;
          }
        }
      });
      this.budget = budgetOptions.convertFromCentsToWholeDollar(newBudgetCents);
    }
  }
}
