




































import { Component, Vue } from 'vue-property-decorator';
import LogoLink from '@/shared/components/site/LogoLink.vue';

@Component({
  components: {
    LogoLink,
  },
  props: {
    layoutClass: String,
  },
})
export default class StepDescription extends Vue {
}
