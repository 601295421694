





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import BackButton from '@/shared/components/site/BackButton.vue';

@Component({
  components: { BackButton },
})
export default class PromoPagePreviewModal extends Vue {
  $refs!: {
    iframeContainer: HTMLDivElement,
    modal: BModal,
  };

  @Prop({ required: true })
  campaignId: string;

  previewURL: string = '';

  show() {
    this.$refs.modal.show();
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }

  handleShown() {
    this.previewURL = `/landing_page/preview/${this.campaignId}?_src=${encodeURIComponent(this.$route.name || '')}`;

    this.$store.dispatch('mixpanel/track', {
      properties: {
        locationSurfaced: this.$route.name,
      },
      action: 'Zire.CustomerAccessedLP',
    });
  }

  handleHidden() {
    this.previewURL = '';
  }
}
