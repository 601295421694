








import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SyncFacebookPageModals from '@/shared/components/modals/SyncFacebookPageModals.vue';
import { FacebookPage } from '@/shared/gen/messages.pisa';

@Component({
  components: {
    SyncFacebookPageModals,
  },
})
export default class SyncFacebookPageComponent extends Vue {
  $refs!: {
    syncFacebookPageModals: any,
  };

  @Getter('onboarding/getFacebookPage') facebookConnection: FacebookPage | undefined;

  get facebookPageId() {
    if (this.facebookConnection && this.facebookConnection.pageId) {
      return this.facebookConnection.pageId;
    }
    return '';
  }

  show() {
    this.$refs.syncFacebookPageModals.show();
  }

  handleSyncFacebookPage(page: any) {
    this.$root.$emit('bv::show::modal', 'updating-campaign-details-modal');
    const loadingPromise = new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    return this.$store.dispatch('onboarding/setFacebookPage', page)
      .then(() => Promise.all([loadingPromise, this.$store.dispatch('onboarding/save')]))
      .then(() => this.$emit('syncFacebookPage', page))
      .finally(() => {
        this.$store.dispatch('mixpanel/trackOnce', {
          properties: {
            numberOfPublishedFacebookPages: this.$store.getters['facebook/getPages'].length,
          },
          action: 'Zire.SocialPageSyncFlowCompleted',
        });
        this.$root.$emit('bv::hide::modal', 'updating-campaign-details-modal');
      });
  }
}
