









































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { validationMixin } from 'vuelidate';
import GridLayout from '@/shared/components/builder/GridLayout.vue';
import Debounce from '@/shared/lib/debounce';

import OpenImageLibraryButton from '@/shared/components/builder/OpenImageLibraryButton.vue';
import ImageLibraryModal from '@/shared/components/builder/ImageLibraryModal.vue';
import GridStrategy from '@/shared/components/builder/GridStrategy.vue';
import GridStrategyOverlay from '@/shared/components/builder/GridStrategyOverlay.vue';
import CampaignInfo from '@/shared/components/builder/CampaignInfo.vue';
import UpdatingCampaignDetailsModal from '@/shared/components/builder/UpdatingCampaignDetailsModal.vue';
import { Ads } from '@/shared/store/recommended_ads';
import { BUILDER_SOURCE_SEARCH, Draft } from '@/shared/store/onboarding';
import { getUGC } from '@/shared/lib/ugc_adaptor';
import {
  Creative,
  FacebookPage,
  UserImage,
  UserAudio,
  MemberImage,
} from '@/shared/gen/messages.pisa';
import { UpsellBanner, showUpsellBanner, dismissUpsellBanner } from '@/shared/lib/upsellBanner';
import MusicIndustry from '@/shared/lib/music_industry';
import LoadingIndicator from '@/shared/components/campaign/LoadingIndicator.vue';
import PromoPageModals from '@/shared/components/common/PromoPageModals.vue';
import SyncFacebookPageComponent from '@/shared/components/builder/SyncFacebookPageComponent.vue';
import PromoPageUpsellBanner from '@/shared/components/common/PromoPageUpsellBanner.vue';
import CustomizeCreatives from '@/shared/components/builder/CustomizeCreatives.vue';

// We declare the props separately
// to make props types inferable.
const RecommendedAdsStepProps = Vue.extend({
  props: {},
});
@Component({
  mixins: [validationMixin],
  components: {
    CustomizeCreatives,
    PromoPageModals,
    LoadingIndicator,
    GridLayout,
    OpenImageLibraryButton,
    ImageLibraryModal,
    GridStrategy,
    GridStrategyOverlay,
    UpdatingCampaignDetailsModal,
    CampaignInfo,
    SyncFacebookPageComponent,
    PromoPageUpsellBanner,
  },
})

export default class RecommendedAdsStep extends RecommendedAdsStepProps {
  $refs!: {
    localGroup: any,
    playableGroup: any,
    feedbackGroup: any,
    nonplayableGroup: any,
    syncFacebookPageComponent: any,
    customizeCreatives: CustomizeCreatives,
  };

  @Getter('onboarding/getDraft') draft: Draft;

  @Getter('onboarding/getDraftImages') selectedImages: UserImage[];

  @Getter('onboarding/getDraftMemberImages') selectedMemberImages: MemberImage[];

  @Getter('recommendedAds/getAds') ads: Ads;

  @Getter('recommendedAds/getImagesByThemeForAds') imagesByTheme: any;

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('layout/isTablet') isTablet: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  @Getter('onboarding/getDraftAudio') draftAudio: Array<UserAudio>;

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('onboarding/getFacebookPage') facebookPage: FacebookPage;

  @Getter('facebook/hasSyncedPages') hasSyncedPages: boolean;

  @Getter('industry/fullNetworkOptions') networks: any[];

  showUpsellBanner: boolean = false;

  get hasSync() {
    return !!(this.facebookPage && this.facebookPage.pageId);
  }

  @Watch('isMobile')
  watchIsMobile(newVal: boolean, oldVal: boolean) {
    if (!newVal && oldVal) {
      this.showEditBox = false;
    }
  }

  @Watch('draftAudio')
  watchDraftAudio() {
    this.$store.dispatch('audioLibrary/getUploadedAudio', { pagination: { page: this.currentPage, perPage: 1000 } }).then((res) => {
      this.maxPage = res.pagination.totalPages;
      this.audioLists = res.userAudio;
    });
  }

  @Watch('isLoggedInWithEmail') logInWatcher() {
    if (this.isLoggedInWithEmail) {
      this.$store.dispatch('audioLibrary/getUploadedAudio', { pagination: { page: 1, perPage: 1000 } }).then((res) => {
        this.audioLists = res.userAudio;
      });
    }
  }

  // draft: Draft = this.$store.getters['onboarding/getDraft'];

  numStrategiesToDisplay: number = -1;

  showEditBox: boolean = false;

  draftLoaded: boolean = false;

  breakpoints: any = {
    0: {
      strategiesPerGroup: 1,
    },
    743: {
      strategiesPerGroup: 2,
    },
    1059: {
      strategiesPerGroup: 3,
    },
    1375: {
      strategiesPerGroup: 4,
    },
    1691: {
      strategiesPerGroup: 5,
    },
  };

  currentPage: number = 1;

  maxPage: number = 0;

  audioLists: any = [];

  strategyTypes = {
    local: 'localGroup',
    instagram: 'storyGroup',
    playable: 'playableGroup',
    feedback: 'feedbackGroup',
    facebook: 'newsfeedGroup',
    click: 'nonplayableGroup',
  }

  showPromoPageModal(adType: string) {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        adChannelType: adType,
        locationInitiatedFrom: 'AdRowToolTip',
        syncedSocialAccounts: this.hasSync,
      },
      action: 'Zire.ViewPromoPageEducationalModal',
    });
    this.$root.$emit('bv::show::modal', 'promo-page-modal');
  }

  editAd(creatives: Creative[], creativeId: string, strategyType: string) {
    if (this.$refs.customizeCreatives) {
      this.$refs.customizeCreatives.editAd(creatives, creativeId, strategyType);
    }
  }

  beforeCreate() {
    this.$store.commit('recommendedAds/setProcessing', true);
    if (!this.hasSyncedPages) {
      this.$store.dispatch('facebook/loadSyncedPages');
    }
    this.$store.dispatch('onboarding/load', this.$route.params.id).finally(() => {
      this.draftLoaded = true;
      const params = {
        id: this.draft.id,
      };
      if (!MusicIndustry.isValid(this.draft, 'recommended-ads', params)) {
        const redirect = MusicIndustry.getAppropriateStep(this.draft, 'recommended-ads', params);
        this.$router.replace(redirect);
      }

      if (this.$route.query.source) {
        this.$store.dispatch('onboarding/setAccessedThrough', this.$route.query.source);
      }

      const numberUserImagesApplied = this.$store.getters['onboarding/getNumDraftImages'];
      const numberStockImagesApplied = this.$store.getters['onboarding/getNumDraftMemberImages'];
      this.$store.dispatch('mixpanel/trackOnce', {
        properties: {
          releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
          releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
          domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
          onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
          numberUserImagesApplied,
          numberStockImagesApplied,
          numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
          hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
          accessedThrough: this.$store.getters['onboarding/getAccessedThrough'],
          referringDiscountCode: this.$store.getters['onboarding/getCouponCode'],
        },
        action: 'Zire.RecommendedAdStepStarted',
      });
      this.$store.dispatch('recommendedAds/get', { campaignId: this.draft.id }).then(() => {
        this.$store.dispatch('recommendedAds/sendDisabledAdsToTheEnd');
      });

      this.$store.dispatch('audioLibrary/getUploadedAudio', { pagination: { page: this.currentPage, perPage: 1000 } }).then((res) => {
        this.maxPage = res.pagination.totalPages;
        this.audioLists = res.userAudio;
      });
    });
  }

  mounted() {
    this.$nextTick(() => {
      this.handleResize();
      window.addEventListener('resize', Debounce(this.handleResize, 150));
    });

    const unwatchHasPromoPage = this.$watch('hasPromoPage', (val: boolean) => {
      if (val) {
        unwatchHasPromoPage();
        if (showUpsellBanner(UpsellBanner.PromoPage)) {
          setTimeout(() => {
            this.showUpsellBanner = true;
          }, 2000);
        }
      }
    });
  }

  handleResize() {
    const breakpoints = Object.keys(this.breakpoints);
    let selectedBreakpoint: any = null;
    if (breakpoints) {
      breakpoints.forEach((point: any) => {
        if (point < window.innerWidth) {
          selectedBreakpoint = this.breakpoints[point];
        }
      });
    }
    if (selectedBreakpoint) {
      this.numStrategiesToDisplay = selectedBreakpoint.strategiesPerGroup;
    } else {
      this.numStrategiesToDisplay = 3;
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  get artistName() {
    return getUGC('artistName');
  }

  get releaseName() {
    return getUGC('releaseName');
  }

  get releaseType() {
    return getUGC('releaseType');
  }

  get releaseGenre() {
    return getUGC('releaseGenre');
  }

  get isProcessing() {
    return this.$store.getters['recommendedAds/isProcessing'];
  }

  get submitDisabled() {
    const anyInteractiveAdEnabled = this.ads.local.some((i) => i.customization.enabled)
      || this.ads.playable.some((i) => i.customization.enabled)
      || this.ads.feedback.some((i) => i.customization.enabled)
      || this.ads.click.some((i) => i.customization.enabled);
    return !anyInteractiveAdEnabled;
  }

  regenerateAds() {
    this.$root.$emit('bv::show::modal', 'updating-campaign-details-modal');
    this.$store.dispatch('recommendedAds/regenerate', { campaignId: this.draft.id }).then(() => {
      this.$root.$emit('bv::hide::modal', 'updating-campaign-details-modal');
    });
  }

  handleImagesSelected({ added = false, removed = false }: { added: boolean, removed: boolean }) {
    this.$root.$emit('bv::show::modal', 'updating-campaign-details-modal');
    let dispatchType = 'recommendedAds/get';
    if (added) {
      dispatchType = 'recommendedAds/regenerate';
    }
    this.$store.dispatch(dispatchType, { campaignId: this.draft.id }).then(() => {
      this.$root.$emit('bv::hide::modal', 'updating-campaign-details-modal');
      if (removed) {
        let issueType = '';

        if (this.ads.local.length < 10) {
          issueType = 'LocalInterestRowIncomplete';
        }

        const noDisplay = this.ads.local.length === 0 && this.ads.feedback.length === 0
          && this.ads.playable.length === 0 && this.ads.click.length === 0;
        const noSocial = this.ads.facebook.length === 0 && this.ads.instagram.length === 0;
        if (noDisplay && noSocial) {
          issueType = 'AllAdsGone';
        } else if (noDisplay) {
          issueType = 'AllInteractiveAdsGone';
        } else if (noSocial) {
          issueType = 'AllSocialAdsGone';
        }

        if (issueType) {
          this.$store.dispatch('mixpanel/trackOnce', {
            properties: {
              issueType,
            },
            action: 'Zire.RAStepAutoGenerationIssue',
          });
        }
      }
    });
  }

  handleSongSelected() {
    this.$root.$emit('bv::show::modal', 'updating-campaign-details-modal');
    const originalPlayableAds = [
      ...this.ads.local.slice(),
      ...this.ads.feedback.slice(),
      ...this.ads.playable.slice(),
    ].filter((i) => i.properties.objective !== 'Click');
    let dispatchType = 'recommendedAds/get';
    if (!originalPlayableAds.length) {
      dispatchType = 'recommendedAds/regenerate';
    }
    this.$store.dispatch(dispatchType, { campaignId: this.draft.id }).then(() => {
      originalPlayableAds.forEach((i) => {
        this.$store.dispatch('creatives/postMessage', { id: `creative:${i.id}`, command: 'zire:reload' });
      });
      this.$root.$emit('bv::hide::modal', 'updating-campaign-details-modal');
    });
  }

  handleCustomizedCreative() {
    const campaignId = this.draft.id;
    this.$store.dispatch('onboarding/load', campaignId); // Reload the draft to pull in latest UGC...
    this.$store.dispatch('recommendedAds/get', { campaignId }); // Update recommended ads (if necessary)...
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    const numberUserImagesApplied = this.$store.getters['onboarding/getNumDraftImages'];
    const numberStockImagesApplied = this.$store.getters['onboarding/getNumDraftMemberImages'];
    const creativeControlMixpanelData = this.$store.getters['recommendedAds/getCreativeControlMixpanelData'];
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        releaseType: this.$store.getters['onboarding/getUGC']('releaseType'),
        releaseGenre: this.$store.getters['onboarding/getUGC']('releaseGenre'),
        domainUrl: this.$store.getters['onboarding/getLandingPageDomains'],
        onboardingType: this.$store.getters['onboarding/getBuilderSource'] === BUILDER_SOURCE_SEARCH ? 'sa_automatic' : 'sa_manual',
        numberUserImagesApplied,
        numberStockImagesApplied,
        numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
        numberUserCopyEditsApplied: creativeControlMixpanelData.numberUserCopyEditsApplied,
        numberUserDisabledAds: creativeControlMixpanelData.numberUserDisabledAds,
        numberIndividuallyEditedAds: creativeControlMixpanelData.numberAdsEdited + creativeControlMixpanelData.numberUserDisabledAds,
        numberEnabledLocalAds: creativeControlMixpanelData.numberEnabledLocalAds,
        numberEnabledPlayableAds: creativeControlMixpanelData.numberEnabledPlayableAds,
        numberEnabledFeedbackAds: creativeControlMixpanelData.numberEnabledFeedbackAds,
        numberEnabledStreamingAds: creativeControlMixpanelData.numberEnabledStreamingAds,
        numberEnabledFBAds: creativeControlMixpanelData.numberEnabledFBAds,
        numberEnabledIGAds: creativeControlMixpanelData.numberEnabledIGAds,
        creativeControlEditsMade: creativeControlMixpanelData.creativeControlEditsMade,
        hasAudioApplied: this.$store.getters['onboarding/draftHasAudio'],
        accessedThrough: this.$store.getters['onboarding/getAccessedThrough'],
        referringDiscountCode: this.$store.getters['onbaording/getCouponCode'],
      },
      action: 'Zire.RecommendedAdStepCompleted',
    });

    let path = '/builder/budget';
    if (this.$route.params.id) {
      path = `/builder/budget/${this.$route.params.id}`;
    }
    this.$router.push(path);
  }

  handleSocialClick(source: string) {
    this.$store.dispatch('mixpanel/trackOnce', {
      properties: {
        locationInitiatedFrom: source,
      },
      action: 'Zire.SocialPageSyncFlowStarted',
    });
    this.$refs.syncFacebookPageComponent.show();
  }

  get hasPromoPage(): boolean {
    return this.ads.facebook.some((i: any) => i.properties.retailers.indexOf('zire') !== -1)
      || this.ads.instagram.some((i: any) => i.properties.retailers.indexOf('zire') !== -1);
  }

  handlePromoPageUpsellBannerClick() {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        locationInitiatedFrom: 'UpsellBanner',
        syncedSocialAccounts: this.hasSync,
      },
      action: 'Zire.ViewPromoPageEducationalModal',
    });
    this.handlePromoPageUpsellBannerDismiss();
    this.$root.$emit('bv::show::modal', 'promo-page-modal');
  }

  handlePromoPageUpsellBannerDismiss() {
    this.showUpsellBanner = false;
    dismissUpsellBanner(UpsellBanner.PromoPage);
  }
}
